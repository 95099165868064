import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Store } from '@ngxs/store';
import { AuthState } from '@stores-states/authentication.state';

import { convertBCDateStringUTCtoLocalDate } from '@helpers/utils/date.utils';

@Pipe({
  name: 'dateExtended',
})
export class DateExtendedPipe implements PipeTransform {
  store = inject(Store);
  transloco = inject(TranslocoLocaleService);
  datePipe = inject(DatePipe);

  transform(value: any, format?: string, includeTime?: boolean): any {
    if (new Date(value).getTime() < new Date('2000-01-01T00:00:00').getTime()) {
      return null;
    }

    if (typeof value === 'number') return value;
    if (!value) return null;

    if (!format)
      format = this.store.selectSnapshot(AuthState.pipeDisplayDataFormat);

    const locale = this.transloco.getLocale();

    let dateString: string | null = null;
    if (value instanceof Date) dateString = value.toISOString(); // YYYY-MM-DDTHH:mm:ss.sssZ
    if (typeof value === 'string') dateString = value;

    if (!dateString || (dateString && isNaN(Date.parse(dateString))))
      return value;

    if (!dateString.includes('0001-01-01T00:00:00') && locale) {
      try {
        let date;
        if (dateString.endsWith('T00:00:00.000Z')) {
          dateString = dateString.split('T')[0];
          date = convertBCDateStringUTCtoLocalDate(dateString);
          includeTime = false;
        }
        if (!dateString.includes('T')) {
          date = convertBCDateStringUTCtoLocalDate(dateString);
          includeTime = false;
        }

        return this.transloco.localizeDate(date ? date : dateString, locale, {
          dateStyle: format as any,
          timeStyle: includeTime ? 'short' : undefined,
        });
      } catch (error) {
        // Fallback to Angular DatePipe
        return this.datePipe.transform(dateString, format, undefined, locale);
      }
    } else {
      return null;
    }
  }
}
